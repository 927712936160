define("admin/serializers/fragment-credential-store-attributes", ["exports", "api/serializers/fragment-credential-store-attributes"], function (_exports, _fragmentCredentialStoreAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fragmentCredentialStoreAttributes.default;
    }
  });
});
